.site-header {position: fixed;top: 0;left: 0;right: 0;z-index: 999;padding: 10px 0;transition: background .2s;
    @media (min-width:@screen-md) {padding: 18px 0;}
    // if scrolled from top
    &.sticky {background: @color-primary;}
    &__inner {.container();
        @media (min-width:@screen-md){display: flex;justify-content: space-between;align-items: center;}
    }
    button {background: none;padding: 0;border: none;outline: none;line-height: 0;}
}
.site-logo {float: left;width: 115px;
    @media (min-width:@screen-md) {width: 140px;}
}
.mini-cart {float: right;
    @media (max-width:@screen-md) {margin-left: 1em;margin-right: 1em;padding-left: 1em;padding-right: 1em;border-left: 1px solid white;border-right: 1px solid white;}
    &__icon {position: relative;line-height: 0;
        .icon {fill: @color-white;height: 35px;width: 35px;}
    }
    &__circle {position: absolute;top: 0;left: 0;height: 18px;width: 18px;background: #ef5356;border-radius: 100%;transform: translate3d(-5px,0,0);color: white;text-align: center;line-height: 18px;font-size: 10px;}
}
.mobile-navigation-trigger {float: right;
    @media (min-width:@screen-md){display: none;}
    .icon {fill: @color-white;height: 35px;width: 35px;}
}
.mobile-navigation-close {position: absolute;right: 1em;top: 1em;
    @media (min-width:@screen-md){display: none;}
    .icon {fill: @color-white;height: 35px;width: 35px;}
}
.site-search-trigger {display: none;
    @media (min-width:@screen-md){display: inline-block;float: right;}
    .icon {fill: @color-white;height: 25px;width: 25px;}
}
.site-search-mobile-trigger {position: absolute;top: 1.6em;left: 2em;
    @media (min-width:@screen-md){display: none;}
    .icon {fill: @color-white;height: 25px;width: 25px;}
}
