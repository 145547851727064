/*===================
Utils
=================== */
.page-loader {background: @color-primary;}
#btn-bar-placeholder {
    position: relative;
    background-color: #fff;
    z-index: 10;
}
.btn-bar {
    position: relative;
    padding: 12px 0;
    background-color: #E5E5E5;
    z-index: 10;
    width: 100%;
    &.stick-to-top {
        position: fixed;
    }
    @media screen and (max-width: 767px) {
        position: fixed;
        bottom: 0;
        top: unset !important;
    }
}
.btn {
    text-decoration: none;
    &.btn-transparent {
        color: #5E5E5E;
        background-color: transparent;
        border: 2px solid #5E5E5E;
        transition-duration: unset;
        transition-property: unset;
        &:before {
            background-color: transparent;
        }
        &:hover {
            background-color: transparent;
            color: #aaa;
        }
        &.hidden-sm-down {
            @media screen and (max-width: 767px) {
                display: none;
            }
        }
        &.hidden-md-up {
            @media screen and (min-width: 768px) {
                display: none;
            }
        }
    }
}
.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {display: none;}
.sidebar .request-product-btn {
    margin-bottom: 40px;
}

// Form popup
.popup-overlay {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 20;
    background-color: rgba(0,0,0,.2);

    .popup-overlay-inner {
        display: table;
        width: 100%;
            height: 100%;
        .popup-container {
            display: table-cell;
            width: 100%;
            height: 100%;
            vertical-align: middle;

            .popup {
                padding: 40px 30px;
                background-color: #3A3A3A;
                margin: 0 auto;
                width: 400px;
                border-radius: 10px;
                h2 {
                    text-align: center;
                    color: white;
                    &::after {
                        display: block;
                        content: "";
                        margin: 20px auto;
                        width: 60px;
                        border-bottom: 1px solid #fff;
                    }
                }
                h4 {
                    margin-top: 30px;
                    color: #fff;
                    text-transform: none;
                    letter-spacing: 1px;
                }
                p {

                    font-size: 1.2em;
                    color: #fff;
                    font-weight: 300;
                    span {
                        display: inline-block;
                        width: 60px;
                    }
                    a {
                        color: #ccc;
                        text-decoration: none;
                        &:hover {
                            color: #fff;
                        }
                    }
                }
                form {

                    .form-group {
                        width: 100%;
                        input:not([type=submit]) {
                            width: 100%;
                        }
                        input[type=submit] {
                            &:hover {
                                color: #ccc;
                            }
                        }
                        &:last-child {
                            margin-bottom: 0
                        }
                    }
                }
            }
        }
    }
}

/*===================
Maps
=================== */

/* MAP */
.google-map {width: 555px;height: 206px;}

/*===================
Sitemap
=================== */
/* Sitemap*/
ul#sitemap {list-style: none;
    ul{
        &.M1, &.M2{list-style: none;}
    }
}

/*===================
Products
=================== */
/* Product */
.product__details {text-align: center;display: block;margin: 0 auto;}
.product__price {font-weight: bold;}
.related__products {
    .owl-nav{
        &>*{overflow: hidden;height: 0;position: absolute;top: 50%;height: 30px;transform: translateY(-50%);text-indent: -333px;width: 17px;background: rgba(0,0,0,.1);border-radius: 50%;width: 30px;border: 1px solid #ccc;
            &:before, &:after{position: absolute;content: '';display: block;width: 10px;height: 3px;background: #777;top: 50%;transform: translate(-50%);margin-top: -1px;}
            &.owl-prev{left: -14px;
                &:before, &:after{ right:25%;}
                &:before{transform:rotate(-45deg);transform-origin:0 100%;}
                &:after {transform:rotate(45deg);transform-origin: 0 0;}
            }
            &.owl-next{right: -14px;
                &:before, &:after{ left: 25%;}
                &:before{transform:rotate(45deg);transform-origin:100% 100%;}
                &:after{transform:rotate(-45deg);transform-origin: 100% 0;}
            }
            &.disabled{border-color: #d9d9d9;
                &:before, &:after{background: #ccc;}
            }
        }
    }
}
.related-product-element img {margin: 0 auto;}
.related-product .related-product-content {padding: 15px 28px;border: 1px solid #f5f5f5;}
.related-product-content h4 {min-height: 60px;}
.related-product-price {display: block;text-align: center;}

/* Variant list */
#variant-list {margin-bottom: 10px;}

/*===================
Slider
=================== */
/* owl slider*/
.slider-thumbs .owl-thumb-item {background: #fff;border: none;max-width: 100%;}

/*===================
Header
=================== */
/* main navigation fix*/
.dropdown-toggle-inactive {pointer-events: none !important; cursor: default !important;}
.breadcrumb-link-inactive {pointer-events: none !important; cursor: default !important;}
.blog-post .blog-element img {text-align: center;display: block;margin: 0 auto;}

/*===================
Footer
=================== */
.footer-widgets a {color: @color-text;}
.services .section-content .col-sm-4:nth-child(3n-2) {clear: left;}

.fa > img {
    width: 20px;
}

ul.bim-objects li {
    margin-bottom: 5px;
}

