/* sidebar */
.sidebar {padding: 0 0 0 30px;
    .widget {margin-bottom: 60px;
        &.popular-posts{
            .widget-content{
                ul {padding: 15px 0 0 0;list-style: none;color: #b0b0b0;
                    .thumb {margin-right: 10px;margin-top: -10px;}
                    .title {color: gray;}
                }
            }
        }
        &.popular-tags{
            a {margin-bottom: 6px;}
        }
        &.categories{
            ul {list-style: none;padding: 0;
                li {line-height: 3em;
                    &:before {content: "\f101";font: normal normal normal 14px/1 FontAwesome;margin-right: 15px;}
                    a {color: black;
                        &:hover {color: gray;}
                    }
                }
            }
        }
    }
}

