/* Cookie pop up*/
/* COOKIE */
/**
  * @desc Cookie Popup styling example
  * @author Danni Larsen - DLA
  * @requires Bootstrap vendor-prefix mixins and a less compiler like Gulp
  */
.cookie-info {position: fixed;left: 0;bottom: 0;width: 100%;background: #fff;padding: 20px 0;transition: 0.3s ease-in-out;transform: translate3d(0, 100%, 0);will-change: transform;opacity: 0;z-index: 999;border-top: 2px solid @dandryer-gray-light;}
.cookie-text p {float: left;margin: 4px 0 0;}
.cookie-info-close {float: none;width: 100%;margin-top: 1em;}
.cookie-info-open {left: 20px;bottom: 0;border: none;background-color: #d6d6d6;font-size: 12px;line-height: 20px;border-radius: 1px 1px 0 0;transition: all .3s;position: fixed;opacity: .6;cursor: pointer;will-change: transform;z-index: 999;
    &:hover {opacity: 1;}
}
/*
*  Open cookie popup styling
*/
body.cookie-info-show .cookie-info {transform: translate3d(0, 0, 0);opacity: 1;}
body.cookie-info-show .cookie-info-open {opacity: 0;}
