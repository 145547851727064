/* footer */
.footer {background-color: @color-primary;color: #FDFDFD;
    @media only screen and (max-width: 767px) {text-align: center;}
    .copyright {color: rgba(255, 255, 255, 0.9);margin: 0;line-height: 100%;display: inline-block;
        @media only screen and (max-width: 767px) {margin-bottom: 15px;}
    }
    .footer-menu {color: rgba(255, 255, 255, 0.9);
        a {color: inherit;}
        li:last-child{
            @media (min-width: 992px) {padding-right: 0;}
        }
    }
    .footer-social-block {margin: 0;list-style-type: none;padding: 0;text-align: center;
        @media only screen and (max-width: 767px) {margin-bottom: 15px;}
        li {display: inline-block;
            a {display: block;color: #e3e1e1;-webkit-transition: color 0.3s;transition: color 0.3s;
                @media only screen and (max-width: 991px) {padding: 0 8px;}
                @media (min-width: 992px) {border-left: 1px solid #464646;padding: 5px 15px;}
                &:hover {color: rgba(255, 255, 255, 0.5);}
            }
            &:last-child{
                a{
                    @media (min-width: 992px) {border-right: 1px solid #464646;}
                }
            }
        }
        .fa{font-size: 24px;}
    }
    &__container{padding-top: 32px ;padding-bottom: 32px;}
}
