.main-navigation {/* Mobile*/position: fixed;transform: translate3d(100%,0,0);transition: all .08s ease-in-out;top: 0;bottom: 0;right: 0;left: 18%;z-index: 1337;padding: 1em 1em 0 2em;background: fade(@color-primary, 95%);
    @media (min-width:@screen-ms){left: 48%;}
    @media (min-width:@screen-md){position: relative;padding: 0;display: inline-block;left: inherit;background: none;transform: translate3d(0,0,0);}
    span { color: inherit; vertical-align: -webkit-baseline-middle; }
    //Mobile Menu Active
    &.js-active {transform: translate3d(0%,0,0);}
    &__inner {.clearfix();
        @media (max-width:@screen-md){overflow-y: scroll;margin-top: 5em;position: absolute;right: 0;bottom: 0;top: 0;left: 0;}
    }
    &__list {padding: 0;margin: 0;list-style-type: none;text-align: right;
        @media (min-width:@screen-md){text-align: left;}
    }
    &__item {display: block;margin-bottom: 1em;
        @media (min-width:@screen-md){display: inline-block;position: relative;margin: 0;}
        //Trigger Sub Nav dropdown
        @media (min-width:@screen-md){
            &:hover {
                > .sub-navigation {display: block;}
            }
        }
    }
    &__link {position: relative;color: white;display: block;text-transform: uppercase;text-decoration: none;line-height: 45px;padding: 0 45px 0 1em;letter-spacing: 2px;
        @media (min-width:@screen-md){line-height: 45px;padding: 0 1em;font-size: 12px;}
        &:hover, &:active, &:focus {color: white;text-decoration: none;
            @media (min-width:@screen-md){text-decoration: underline;}
        }
        &--active {color: white;text-decoration: underline;
            &:hover {color: white;}
        }
        &--child-active {color: white;text-decoration: underline;
            &:hover {color: white;}
            .icon {fill: currentColor;}
        }
        .icon {width: 10px;height: 10px;}
    }
}

//Sub navigation (dropdown + mobile fold out)
.sub-navigation {display: none;list-style-type: none;margin: 0;padding: 0;background: darken(@color-primary, 3%);
    @media (min-width:@screen-md){background: transparent;position: absolute;top: 100%;left: 0;min-width: 235px;}
    &__item {display: block;position: relative;
        //Trigger Sub Nav dropdown
        @media (min-width:@screen-md){
            &:hover {
                > .sub-navigation {display: block;top: 0;left: 100%;}
            }
        }
        > .sub-navigation {background: darken(@color-primary, 5%);
            .sub-navigation__link {padding-right: 75px;}
        }
    }
    &__link {position: relative;padding-right: 55px;line-height: 1.7;padding-top: 1em;padding-bottom: 1em;display: block;text-transform: uppercase;text-decoration: none;color: white;font-size: 0.9em;letter-spacing: 2px;transition: background .2s;border-bottom: 1px solid @color-primary;
        @media (min-width:@screen-md){padding: 1.5em 2.5em 1.5em 1.5em;line-height: 1;background: rgba(red(@color-primary),green(@color-primary),blue(@color-primary),.9);color: white;border-bottom: none;}
        &:hover, &:active, &:focus {color: white;text-decoration: none;
            @media (min-width:@screen-md){background: @color-black;}
        }
        &--active {color: white;text-decoration: underline;font-weight: bold;
            &:hover {color: white;text-decoration: underline;}
        }
        button {position: absolute;
            @media (min-width:@screen-md){right: 0.65em;}
        }
        .icon {width: 10px;height: 10px;}
    }
}

.js-sub-navigation-trigger {position: absolute;right: 0;width: 45px;top: 0;bottom: 0;z-index: 501;
    @media (min-width:@screen-md){position: initial;right: auto;width: auto;height: auto;z-index: inherit;margin-left: 5px;}
}
