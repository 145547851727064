/**
 * Grid framework mixin
 * Used only by the grid to generate the correct number of grid classes given any value of '@grid-columns'
 */

.make-grid-columns() {
    // Common styles for all sizes of grid columns, widths 1-12
    .col(@index) { // initial
        @item: ~".col--xs-@{index}, .col--ms-@{index}, .col--sm-@{index}, .col--md-@{index}, .col--lg-@{index}";
        .col((@index + 1), @item);
    }
    .col(@index, @list) when (@index =< @grid-columns) { // general; "=<" isn't a typo
        @item: ~".col--xs-@{index}, .col--ms-@{index}, .col--sm-@{index}, .col--md-@{index}, .col--lg-@{index}";
        .col((@index + 1), ~"@{list}, @{item}");
    }
    .col(@index, @list) when (@index > @grid-columns) { // terminal
        @{list} {
            position: relative;
            // Prevent columns from collapsing when empty
            min-height: 1px;
            // Inner gutter via padding
            padding-left:  ceil((@gutter / 2));
            padding-right: floor((@gutter / 2));

            @media (min-width:@screen-ms) {
                padding-left:  ceil((@gutter-ms / 2));
                padding-right: floor((@gutter-ms / 2));
            }

            @media (min-width:@screen-sm) {
                padding-left:  ceil((@gutter-sm / 2));
                padding-right: floor((@gutter-sm / 2));
            }

            @media (min-width:@screen-md) {
                padding-left:  ceil((@gutter-md / 2));
                padding-right: floor((@gutter-md / 2));
            }

            @media (min-width:@screen-lg) {
                padding-left:  ceil((@gutter-lg / 2));
                padding-right: floor((@gutter-lg / 2));
            }
        }
    }
    .col(1); // kickstart it
}

.float-grid-columns(@class) {
    .col(@index) { // initial
        @item: ~".col--@{class}-@{index}";
        .col((@index + 1), @item);
    }
    .col(@index, @list) when (@index =< @grid-columns) { // general
        @item: ~".col--@{class}-@{index}";
        .col((@index + 1), ~"@{list}, @{item}");
    }
    .col(@index, @list) when (@index > @grid-columns) { // terminal
        @{list} {
            float: left;
        }
    }
    .col(1); // kickstart it
}

.calc-grid-column(@index, @class, @type) when (@type = width) and (@index > 0) {
    .col--@{class}-@{index} {
        width: percentage((@index / @grid-columns));
    }
}
.calc-grid-column(@index, @class, @type) when (@type = push) and (@index > 0) {
    .col--@{class}-push-@{index} {
        left: percentage((@index / @grid-columns));
    }
}
.calc-grid-column(@index, @class, @type) when (@type = push) and (@index = 0) {
    .col--@{class}-push-0 {
        left: auto;
    }
}
.calc-grid-column(@index, @class, @type) when (@type = pull) and (@index > 0) {
    .col--@{class}-pull-@{index} {
        right: percentage((@index / @grid-columns));
    }
}
.calc-grid-column(@index, @class, @type) when (@type = pull) and (@index = 0) {
    .col--@{class}-pull-0 {
        right: auto;
    }
}
.calc-grid-column(@index, @class, @type) when (@type = offset) {
    .col--@{class}-offset-@{index} {
        margin-left: percentage((@index / @grid-columns));
    }
}

// Basic looping in LESS
.loop-grid-columns(@index, @class, @type) when (@index >= 0) {
    .calc-grid-column(@index, @class, @type);
    // next iteration
    .loop-grid-columns((@index - 1), @class, @type);
}

// Create grid for specific class
.make-grid(@class) {
    .float-grid-columns(@class);
    .loop-grid-columns(@grid-columns, @class, width);
    .loop-grid-columns(@grid-columns, @class, pull);
    .loop-grid-columns(@grid-columns, @class, push);
    .loop-grid-columns(@grid-columns, @class, offset);
}
