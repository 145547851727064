.language-picker {line-height: 0;float: right;position: relative;
	&__list {display: none;background: @color-white;box-shadow: 0px 3px 8px 0px fade(@color-black, 40%);
		&.js-active {display: block;position: absolute;top: 45px;padding: 0;margin: 0;text-align: center;left: 50%;transform: translateX(-50%);}
	}
	&__item {display: block;width: 50px;color: @color-primary;line-height: 1em;padding: 1em 0;margin: 0;
		a {display: block;}
	}
}
.language-picker-trigger {
	.icon {fill: @color-white; height: 35px;width: 30px;}
}
