﻿/* blog */
.blog {
    .blog-post {margin-bottom: 40px;
        header {position: relative;
            .date {background-color: #fdfdfd;position: absolute;padding: 10px;text-align: center;font-weight: 500;z-index: 9;color: #2c2c2c;}
            .image-slider-carousel{
                .carousel-control{
                    &.left {
                        @media (min-width: 768px) {top: 200px;bottom: 5px;left: 220px;}
                        @media (min-width: 992px) {top: 210px;bottom: 5px;left: 235px;}
                    }
                    &.right{
                        @media (min-width: 768px) {top: 200px;}
                        @media (min-width: 992px) {top: 210px;right: 10px;bottom: 5px;}
                    }
                }
            }
        }
        .blog-content {padding: 15px 28px;border: 1px solid #f5f5f5;
            h4 {margin: 20px 0;letter-spacing: 1px;min-height: 60px;
                a {color: #333;}
            }
            .post-meta {text-transform: uppercase;margin-bottom: 18px;padding-bottom: 10px;font-size: 12px;border-bottom: 1px solid #F5F5F5;
                span {color: #CCC;padding-right: 10px;border-right: 1px solid #CCC;margin-right: 10px;
                    &:first-child{
                        a{color: #848484;}
                    }
                    a {color: #CCC;border-bottom: 1px transparent;
                        &:hover {text-decoration: none;border-bottom-style: solid;border-color: inherit;}
                    }
                    &:last-child {margin-right: 0;border-right: 0;padding-right: 0;}
                }
            }
            p {color: #67666a;line-height: 22px;}
        }
        footer {border: 1px solid #DDD;padding: 0px 15px;
            .comment-count {color: #000;position: relative;padding-left: 25px;
                &:hover,&:focus {color: #000;}
            }
            .read-more {color: #000;position: relative;padding-left: 25px;padding-right: 0;text-transform: uppercase;
                &:hover,&:focus {color: #000;}
            }
        }
    }
}
.blog-standard{
    .blog-post {margin-bottom: 80px;
        &:last-child {margin-bottom: 0;}
        .featured-image {margin-bottom: 30px;}
        .post-title {margin-bottom: 15px;
            &> a {color: #5c5c5c;
                &:hover {color: #000000;text-decoration: none;}
            }
        }
        h1.post-title {font-size: 36px;text-transform: uppercase;margin-top: 0;margin-bottom: 0px;word-spacing: 8px;letter-spacing: 2px;}
        @media only screen and (max-width: 767px) {
            h1.post-title {font-size: 30px;letter-spacing: 1px;word-spacing: 2px;}
        }
        .post-meta {color: silver;letter-spacing: 1px;text-transform: uppercase;font-size: 11px;margin-bottom: 30px;
            a {color: #656565;}
            span {margin: 0 6px;}
        }
        .read-more {margin-top: 25px;
            a {text-transform: uppercase;color: black;-webkit-transition: all 0.2s;transition: all 0.2s;
                &:hover {color: silver;text-decoration: none;}
            }
        }
    }
}
