﻿.search-dialog {display: none;position: fixed;top: 0;left: 0;right: 0;bottom: 0;height: 100%;width: 100%;background: transparent;border: none;z-index: 1400;padding: 0;
    &:before {content: "";position: absolute;top: 0;bottom: 0;left: 0;right: 0;background: transparent;}
    &.js-active {display: block;
        &:before {background: rgba(19, 19, 19, 0.9);}
    }
    &__inner {display: flex;flex-wrap: wrap;justify-content: center;align-content: center;height: 100%;width: 100%;padding: 0 15px;}
}
.search-dialog-form {position: relative;align-self: center;transform: translateY(-50%);font-size: 1em;display: flex;flex-direction: column;width: 100%;
    @media (min-width:@screen-md){width: 60%;transform: translateY(-100%);}
    &__title {color: white;font-size: 1.2em;text-align: center;margin: 0 auto 1em;border: none;}
}
.search-dialog-input {border: none;background: white;padding: 0 3.6em 0 2em;display: block;overflow: hidden;line-height: 3.6em;height: 3.6em;
    &:focus {outline: none;}
}
.search-dialog-button {align-self: flex-end;transform: translateY(-100%);height: 3.6em;width: 3.6em;border: none;background: transparent;margin: 0;padding: 0;line-height: 1;}
