/**
 * Base: Browser fixes
 */

// Windows 7/8 IE fix
@-ms-viewport {
    width: auto;
}

// Windows phone fix
@-webkit-viewport{width:device-width}
@-moz-viewport{width:device-width}
@-ms-viewport{width:device-width}
@-o-viewport{width:device-width}
@viewport{width:device-width}
