/**
 * Base
 */

// Box sizing
* {
    box-sizing: border-box;

    &:before,
    &:after {box-sizing: border-box;}
}


// Body
body {
    background-color: @site-background-color;
    font-family: @base-font-family;
    font-weight: @base-font-weight;
    font-size: @base-font-size;
    color: @color-text;
    line-height: 1.45;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    &.mobile-nav-open {
        position: fixed;
    }
}


// Links
a {
    color: @color-link;
    text-decoration: underline;

    &:hover,
    &:focus {color: darken(@color-link, 10%);}
}


// Image handling
figure {
    margin: 0;
    background-size: auto 100%;
    background-position: center center;
    background-repeat: no-repeat;

    img {
        max-width: 100%;
        width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
        transition: opacity 0.2s;
    }
	
	&.contain-width img { width: auto; } 
}
