//Product calculator
@font-face {
    font-family: 'icomoon';
    src: url('/Files/Templates/Designs/DanDryer/dist/fonts/icomoon.eot?5kiojs');
    src: url('/Files/Templates/Designs/DanDryer/dist/fonts/icomoon.eot?5kiojs#iefix') format('embedded-opentype'), url('/Files/Templates/Designs/DanDryer/dist/fonts/icomoon.ttf?5kiojs') format('truetype'), url('/Files/Templates/Designs/DanDryer/dist/fonts/icomoon.woff?5kiojs') format('woff'), url('/Files/Templates/Designs/DanDryer/dist/fonts/icomoon.svg?5kiojs#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

.thin {
    font-weight: 100;
    color: @color-text;
}

.with-border {
    border: 1px solid #ddd;
    padding-bottom: 15px;
    padding-top: 15px;
}

.product-dropdown {
    border-radius: 15px;
    border: 2px solid #5F5F5E;
    color: #595959 !important;
    height: 50px;
    text-transform: uppercase;

    &:after {
        position: absolute;
        content: "/Files/Templates/Designs/DanDryer/dist/icons/icons.svg#triangle";
        top: 14px;
        right: 10px;
        width: 17px;
        height: 16px;
    }
}

.margin-bottom--lg-mobile {
    @media (max-width:@screen-lg) {
        margin-bottom: 2rem;
    }
}

.margin-top--lg-mobile {
    @media (max-width:@screen-lg) {
        margin-top: 2rem;
    }
}

.margin-top--lg {
    margin-top: 2rem;
}

.margin-top--xl {
    margin-top: 3rem;
}

.dropdown-arrow {
    width: 10px;
    height: 10px;
    left: 25px;
    position: relative;
}

.dryer-slider-container {
    @media (min-width:@screen-md) {
        padding-right: 9.5rem;
    }

    margin-top: 1.5rem;
}
/* Or display content like unicode characters or fontawesome icons */
.slider-handle.custom::before {
    line-height: 20px !important;
    font-size: 35px !important;
    font-family: 'icomoon';
    content: '\e901' !important;
    color: inherit !important;
    margin-left: -20px !important;
}

.slider-handle.custom::after {
    line-height: 20px !important;
    font-size: 28px !important;
    font-family: 'icomoon';
    content: '\e903' '\e902' !important;
    color: white !important;
    margin-left: -15px !important;
    position: absolute;
    top: 0;
    letter-spacing: -45px;

    @media screen and (min--moz-device-pixel-ratio:0) {
        margin-left: -55px !important;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        margin-left: -55px !important;
    }
}


.slider-tick.custom {
    box-shadow: none !important;
}

.slider-tick.custom::before {
    content: '' !important; /*unicode star character*/
}

.slider-tick.in-selection {
    background-image: none !important;
}

.slider-selection.tick-slider-selection {
    background-image: none !important;
}

.slider-selection {
    background-image: none !important;
    box-shadow: none !important;
}

.slider-track {
    background-image: none !important;
    box-shadow: none !important;
}

.slider.slider-horizontal {
    width: 100% !important;
    top: -15px !important;
}

.no-letter-spacing {
    letter-spacing: 0;
}

.align-me-center {
    display: block;
    margin: 0 auto;
}

.bold {
    font-weight: bold;
}

.margin-bottom--lg {
    margin-bottom: 2rem;
}

.margin-bottom--xs {
    margin-bottom: 5px;
}

.no-margin-bottom {
    margin-bottom: 0;
}

.text-grey {
    color: @color-text;
}

.semi-bold {
    color: @color-text;
    font-weight: 300;
}

.green-text {
    color: #499040;
}

.d-flex.justify-center {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    flex-direction: column;
    align-items: center;

    a {
        width: 100%;

        @media (min-width: @screen-sm) {
            width: 75%;
        }
    }
}

.btn-product-link {
    border: 2px solid #5F5F5E;
}

.btn-calculation {
    background-color: #5F5F5E;
    color: white;
}

.product-rich-text {
    padding-left: 17px;
}

.tooltip-icon {
    vertical-align: text-bottom;
    margin-left: 5px;
}

.d-block {
    display: block;
}

.padding-b-t {
    padding-top: 15px;
    padding-bottom: 15px;
}

.no-padding-right {
    @media (min-width:@screen-md) {
        padding-right: 0;
    }
}

.slider.slider-horizontal {
    .tooltip {
        transform: translateX(-45%) !important;
    }
}

.slider-tick-label {
    padding-top: 10px !important;

    p {
        display: none;

        @media (min-width:@screen-md) {
            display: block;
        }
    }
}

.margin-bottom--sm {
    margin-bottom: 1rem;
}

.w-100 {
    width: 100%;
}

.small-margin-mobile {
    margin-right: 0px;
    margin-left: 0px;

    @media (min-width:@screen-md) {
        margin-right: -15px;
        margin-left: -15px;
    }
}

.mt-1 {
    margin-top: 15px;
}



.dryings-headline {
    margin-top: 3rem;
}

.yearly-savings-headline {
    margin-top: 3rem;
}

.yearly-savings-paragraph {
    margin-bottom: 1rem;
}

.seo-content-container {
    @media (min-width:@screen-md) {
        padding-left: 5px;
    }


    p {
        padding-left: 2px;
    }
}

.product-name {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 14px;
}

.clickable {
    cursor: pointer;
}

@media print {
    .no-print {
        visibility: hidden;
        display: none;
    }

    .site-header {
        visibility: hidden;
        display: none;
    }

    .header-inner {
        visibility: hidden;
        display: none;
    }

    .footer-widgets {
        visibility: hidden;
        display: none;
    }

    .footer {
        visibility: hidden;
        display: none;
    }

    .cookie-info-open {
        visibility: hidden;
        display: none;
    }

    #totop {
        visibility: hidden;
        display: none;
    }
}

.no-text-transform {
    text-transform: unset;
}

.contact-form-calculator-headline {
    color: white;
    font-weight: bold;
    font-size: 14px;
}

.contact-form-calculator-paragraph {
    color: white;
    font-size: 12px;
}

.yearly-envoirment-savings {
    margin-top: 7rem;
}


.custom-margin-top-24px {
    margin-top: 24px;
}

.custom-margin-top {
    margin-top: 30px;
}
