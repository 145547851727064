.portfolio-grid {
    .da-thumbs {background-color: @color-white;
        li {overflow: hidden;cursor: pointer;
            a {display: block;
                @media (min-width:@screen-md) {
                    &:hover {
                        &:before {box-shadow: inset 0 0 0 500px fade(@color-primary, 35%);}
                        img {transform: scale(1.1);}
                        span {top: 70%;}
                    }
                }
                &:before {content: '';display: block;position: absolute;z-index: 3;width: 100%;height: 100%;box-shadow: inset 0 0 0 500px fade(@color-primary, 45%);transition: all .3s;backface-visibility: hidden;}
                img {display: block;width: 100%;max-width: 100%;transition: transform .3s ease;backface-visibility: hidden;transform: translate3d(0,0,0);}
                span {position: absolute;top: 50%;transform: translateY(-50%);z-index: 4;margin: 0;width: 100%;text-align: center;color: @color-white;font-weight: 900;transition: top .3s ease;text-transform: uppercase;letter-spacing: 1px;}
            }
        }
    }
}
