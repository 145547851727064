/* footer-widgets */
.footer-widgets {background:@dandryer-gray-light;color: @color-text;padding-top: 0;padding-bottom: 0;
    p {display: inline-block;}
    h4 {color: @color-text;margin-bottom: 40px;padding-bottom: 25px;border-bottom: 1px solid #3c3c3c;
        @media only screen and (min-width: 992px) {min-height: 64px;}
        @media only screen and (min-width: 1200px) {min-height: 0;}
    }
    .content-wrap{
        @media only screen and (max-width: 991px) {margin-bottom: 80px;}
        &.widget-contact{
            @media only screen and (min-width: 768px) {padding-left: 25px;}
            ul {padding: 0;list-style: none;margin: 0;
                li {margin-bottom: 25px;
                    i {margin-right: 6px;float: left;}
                }
            }
        }
        &.widget-recent-posts{
            @media only screen and (min-width: 992px) {padding-left: 25px;}
            ul {padding: 0;list-style: none;margin: 0;
                li {margin-bottom: 20px;
                    .post-title {display: block;color: @color-text;}
                    .author {color: inherit;}
                }
            }
        }
        &.widget-gallery{
            @media only screen and (min-width: 768px) {padding-left: 25px;}
            a {width: 95px;margin-right: 5%;margin-bottom: 5%;float: left;background-color: black;background-size: cover;-webkit-transition: opacity 0.2s;transition: opacity 0.2s;
                @media only screen and (min-width: 992px) {width: 75px;}
                @media only screen and (min-width: 1200px) {width: 95px;}
                &:hover {opacity: 0.75;}
            }
        }
    }
    &__container{padding-top: 30px ;padding-bottom: 30px;}
    &__watermark{display:block;position: absolute;bottom: 0px;right: 110px;opacity: 0.4;height:400px;overflow: hidden;}
    &__watermark-icon{width: 500px;height: 1040px;fill: #444;}
    &__widget-recent-posts{
        @media only screen and (min-width: 768px) {clear: left;}
        @media only screen and (min-width: 992px) {clear: none;}
    }
}
