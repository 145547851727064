/**
 * Grid mixin
 * Generate grid columns with these mixins
 */

// Centered container element
.container-fixed() {
    margin-right: auto;
    margin-left: auto;
    padding-left: (@gutter / 2);
    padding-right: (@gutter / 2);
    &:extend(.clearfix all);

    @media (min-width:@screen-ms) {
        padding-left: (@gutter-ms / 2);
        padding-right: (@gutter-ms / 2);
    }

    @media (min-width:@screen-sm) {
        padding-left: (@gutter-sm / 2);
        padding-right: (@gutter-sm / 2);
    }

    @media (min-width:@screen-md) {
        padding-left: (@gutter-md / 2);
        padding-right: (@gutter-md / 2);
    }

    @media (min-width:@screen-lg) {
        padding-left: (@gutter-lg / 2);
        padding-right: (@gutter-lg / 2);
    }
}

// Creates a wrapper for a series of columns
.make-row() {
    margin-left:  (@gutter / -2);
    margin-right: (@gutter / -2);
    &:extend(.clearfix all);

    @media (min-width:@screen-ms) {
        margin-left:  (@gutter-ms / -2);
        margin-right: (@gutter-ms / -2);
    }

    @media (min-width:@screen-sm) {
        margin-left:  (@gutter-sm / -2);
        margin-right: (@gutter-sm / -2);
    }

    @media (min-width:@screen-md) {
        margin-left:  (@gutter-md / -2);
        margin-right: (@gutter-md / -2);
    }

    @media (min-width:@screen-lg) {
        margin-left:  (@gutter-lg / -2);
        margin-right: (@gutter-lg / -2);
    }
}

// Generate the extra small columns
.make-xs--column(@columns) {
    position: relative;
    float: left;
    width: percentage((@columns / @grid-columns));
    min-height: 1px;
    padding-left:  (@gutter-xs / 2);
    padding-right: (@gutter-xs / 2);
}
.make-xs--column-offset(@columns) {
    margin-left: percentage((@columns / @grid-columns));
}
.make-xs--column-push(@columns) {
    left: percentage((@columns / @grid-columns));
}
.make-xs--column-pull(@columns) {
    right: percentage((@columns / @grid-columns));
}

// Generate the Small medium columns
.make-ms--column(@columns) {
    position: relative;
    min-height: 1px;
    padding-left:  (@gutter-ms / 2);
    padding-right: (@gutter-ms / 2);

    @media (min-width: @screen-ms-min) {
        float: left;
        width: percentage((@columns / @grid-columns));
    }
}
.make-ms--column-offset(@columns) {
    @media (min-width: @screen-ms-min) {
        margin-left: percentage((@columns / @grid-columns));
    }
}
.make-ms--column-push(@columns) {
    @media (min-width: @screen-ms-min) {
        left: percentage((@columns / @grid-columns));
    }
}
.make-ms--column-pull(@columns) {
    @media (min-width: @screen-ms-min) {
        right: percentage((@columns / @grid-columns));
    }
}

// Generate the small columns
.make-sm--column(@columns) {
    position: relative;
    min-height: 1px;
    padding-left:  (@gutter-sm / 2);
    padding-right: (@gutter-sm / 2);

    @media (min-width: @screen-sm-min) {
        float: left;
        width: percentage((@columns / @grid-columns));
    }
}
.make-sm--column-offset(@columns) {
    @media (min-width: @screen-sm-min) {
        margin-left: percentage((@columns / @grid-columns));
    }
}
.make-sm--column-push(@columns) {
    @media (min-width: @screen-sm-min) {
        left: percentage((@columns / @grid-columns));
    }
}
.make-sm--column-pull(@columns) {
    @media (min-width: @screen-sm-min) {
        right: percentage((@columns / @grid-columns));
    }
}

// Generate the medium columns
.make-md--column(@columns) {
    position: relative;
    min-height: 1px;
    padding-left:  (@gutter-md / 2);
    padding-right: (@gutter-md / 2);

    @media (min-width: @screen-md-min) {
        float: left;
        width: percentage((@columns / @grid-columns));
    }
}
.make-md--column-offset(@columns) {
    @media (min-width: @screen-md-min) {
        margin-left: percentage((@columns / @grid-columns));
    }
}
.make-md--column-push(@columns) {
    @media (min-width: @screen-md-min) {
        left: percentage((@columns / @grid-columns));
    }
}
.make-md--column-pull(@columns) {
    @media (min-width: @screen-md-min) {
        right: percentage((@columns / @grid-columns));
    }
}

// Generate the large columns
.make-lg--column(@columns) {
    position: relative;
    min-height: 1px;
    padding-left: (@gutter-lg / 2);
    padding-right: (@gutter-lg / 2);

    @media (min-width: @screen-lg-min) {
        float: left;
        width: percentage((@columns / @grid-columns));
    }
}
.make-lg--column-offset(@columns) {
    @media (min-width: @screen-lg-min) {
        margin-left: percentage((@columns / @grid-columns));
    }
}
.make-lg--column-push(@columns) {
    @media (min-width: @screen-lg-min) {
        left: percentage((@columns / @grid-columns));
    }
}
.make-lg--column-pull(@columns) {
    @media (min-width: @screen-lg-min) {
        right: percentage((@columns / @grid-columns));
    }
}
